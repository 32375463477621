import Vue from "vue";
import VueRouter from "vue-router";

import Layout from "@/layout/index.vue";

Vue.use(VueRouter);
const isMobile = /Mobi|Android/i.test(navigator.userAgent);

const routes = [
  {
    path: "/",
    name: "index",
    component: Layout,
    children: [
      {
        // 首页
        path: "",
        name: "Home",
        component: isMobile
          ? () => import("@/views/mobile/index.vue")
          : () => import("@/views/index.vue"),
        // component: () => import("@/views/index.vue"),
        meta: { title: "未来公民署-FCB" },
      },
      {
        // 关于我们
        path: "aboutfcb",
        name: "aboutFCB",
        component: isMobile
          ? () => import("@/views/mobile/about.vue")
          : () => import("@/views/aboutFCB/index.vue"),
        // component: () => import("@/views/aboutFCB/index.vue"),
        meta: { title: "未来公民署-FCB" },
      },
      {
        // 项目计划-巴哈马
        path: "bahamas",
        name: "Bahamas",
        component: isMobile
          ? () => import("@/views/mobile/bahamas.vue")
          : () => import("@/views/projectPlan/Bahamas.vue"),
        // component: () => import("@/views/projectPlan/Bahamas.vue"),
        meta: { title: "未来公民署-FCB" },
      },
      {
        // 项目计划-爱尔兰
        path: "ireland",
        name: "Ireland",
        component: isMobile
          ? () => import("@/views/mobile/ireland.vue")
          : () => import("@/views/projectPlan/Ireland.vue"),
        // component: () => import("@/views/projectPlan/Ireland.vue"),
        meta: { title: "未来公民署-FCB" },
      },
      {
        // 项目计划-斯洛文尼亚
        path: "slovenia",
        name: "Slovenia",
        component: isMobile
          ? () => import("@/views/mobile/slovenia.vue")
          : () => import("@/views/projectPlan/Slovenia.vue"),
        // component: () => import("@/views/projectPlan/Slovenia.vue"),
        meta: { title: "未来公民署-FCB" },
      },
      {
        // 业务服务-公民服务
        path: "citizenservice",
        name: "citizenService",
        component: isMobile
          ? () => import("@/views/mobile/citizen.vue")
          : () => import("@/views/business/citizenService.vue"),
        // component: () => import("@/views/business/citizenService.vue"),
        meta: { title: "未来公民署-FCB" },
      },
      {
        // 业务服务-企业服务
        path: "enterpriseservice",
        name: "enterpriseService",
        component: isMobile
          ? () => import("@/views/mobile/enterprise.vue")
          : () => import("@/views/business/enterpriseService.vue"),
        // component: () => import("@/views/business/enterpriseService.vue"),
        meta: { title: "未来公民署-FCB" },
      },
      {
        // 业务服务-政府服务
        path: "governmentservice",
        name: "governmentService",
        component: isMobile
          ? () => import("@/views/mobile/government.vue")
          : () => import("@/views/business/governmentService.vue"),
        // component: () => import("@/views/business/governmentService.vue"),
        meta: { title: "未来公民署-FCB" },
      },
      {
        // 新闻资讯
        path: "news",
        name: "news",
        component: isMobile
          ? () => import("@/views/mobile/newslist.vue")
          : () => import("@/views/news/index.vue"),
        meta: { title: "未来公民署-FCB" },
      },
      {
        // 新闻资讯详情
        path: "newsdetails",
        name: "newsDetails",
        component: isMobile
          ? () => import("@/views/mobile/newsDetails.vue")
          : () => import("@/views/news/newsDetails.vue"),
        meta: { title: "未来公民署-FCB" },
      },
      {
        // 联系我们
        path: "contactus",
        name: "contactUs",
        component: isMobile
          ? () => import("@/views/mobile/contact.vue")
          : () => import("@/views/contactUs/index.vue"),
        // component: () => import("@/views/contactUs/index.vue"),
        meta: { title: "未来公民署-FCB" },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to, from) => {
  document.title = to.meta.title; //在全局后置守卫中获取路由元信息设置title
  window.scrollTo(0, 0);
});

export default router;
