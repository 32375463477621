//axios实例封装
import axios from "axios";
const service = axios.create({
  //基础路径URL配置
  baseURL: "",
  //五秒未响应提示
  timeout: 5000,
});

//请求拦截
service.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//响应拦截器
service.interceptors.response.use(
  (response) => {
    //只返回config内的data的数据，其他的不展示
    const res = response.data;
    return res;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default service;
