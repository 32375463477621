<template>
  <div>
    <nav class="navbar">
      <div class="logo">
        <img src="@/assets/mobi_logo.png" />
      </div>
      <div class="menu-icon" @click="toggleDrawer">
        <img src="@/assets/navbar_icon.png" alt="Menu Icon" />
      </div>
    </nav>
    <div
      class="drawer_div"
      :class="{ 'drawer-open': drawerOpen }"
      @click.self="closeDrawer"
    >
      <div class="drawer" :class="{ 'drawer-open': drawerOpen }">
        <div class="drawer-content">
          <div class="close" @click="closeDrawer"></div>
          <div class="mobi_lang">
            <span class="on" @click="changeLanguage(0)">CN</span>
            <img src="@/assets/cn.svg" alt="" @click="changeLanguage(0)" />
            <span @click="changeLanguage(1)">EN</span>
            <img src="@/assets/gb.svg" alt="" @click="changeLanguage(1)" />
          </div>
          <ul class="drawer_ul">
            <template v-for="(nav, key) in navList">
              <li
                v-if="nav.childlist.length == 0"
                class="router"
                @click="to(nav.link)"
              >
                {{ nav.name }}
              </li>
              <li v-else class="router more" @click="show(key)">
                {{ nav.name }}
                <div class="list">
                  <template v-if="nav.childlist[0].childlist.length != 0">
                    <template v-for="nav2 in nav.childlist">
                      <div>{{ nav2.name }}</div>
                      <ul>
                        <template v-for="nav3 in nav2.childlist">
                          <li @click="to(nav3.link)">• {{ nav3.name }}</li>
                        </template>
                      </ul>
                    </template>
                  </template>
                  <template v-else>
                    <template v-for="nav2 in nav.childlist">
                      <div @click="to(nav2.link)">{{ nav2.name }}</div>
                    </template>
                  </template>
                </div>
              </li>
            </template>
          </ul>
          <div class="router_bottom">
            <img src="@/assets/contact_f.png" alt="" />
            <img src="@/assets/contact_x.png" alt="" />
            <img src="@/assets/contact_z.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { GetNav } from "@/api/api";
export default {
  name: "App",
  data() {
    return {
      drawerOpen: false,
      screenWidth: document.body.clientWidth,
      proShow: false,
      serShow: false,
      navList: [],
    };
  },
  created() {
    GetNav().then((res) => {
      if (res.data.code == 1) {
        this.navList = res.data.data;
      }
    });
    $(function () {
      if (localStorage.getItem("language") == "zh-CN") {
        $(".mobi_lang span:eq(0)")
          .addClass("on")
          .siblings("span")
          .removeClass("on");
      } else {
        $(".mobi_lang span:eq(1)")
          .addClass("on")
          .siblings("span")
          .removeClass("on");
      }
    });
  },
  watch: {
    screenWidth: {
      handler: !(function (a, b) {
        function c() {
          var a = b.documentElement.clientWidth,
            c = 36 * (a / 375);
          console.log(c > 36 ? (c > 72 ? 72 : c) : 36);
          b.getElementsByTagName("html")[0].style.fontSize =
            (c > 36 ? (c > 72 ? 72 : c) : 36) + "px";
        }

        c();
        a.addEventListener("resize", c, !1);
      })(window, document),
    },
  },
  mounted() {
    !(function (a, b) {
      function c() {
        var a = b.documentElement.clientWidth,
          c = 36 * (a / 375);
        b.getElementsByTagName("html")[0].style.fontSize =
          (c > 36 ? (c > 72 ? 72 : c) : 36) + "px";
      }

      c();
      a.addEventListener("resize", c, !1);
    })(window, document);
    $(".drawer_div .drawer-content .mobi_lang span").click(function (e) {
      $(this).addClass("on").siblings("span").removeClass("on");
    });

    // 监听事件
    window.addEventListener("scroll", function () {
      let t = $("body, html").scrollTop(); // 目前监听的是整个body的滚动条距离
      if (t > 0) {
        $(".navbar").addClass("navbar-active");
      } else {
        $(".navbar").removeClass("navbar-active");
      }
    });
  },
  methods: {
    changeLanguage(_index) {
      $(".mobi_lang span:eq('" + _index + "')")
        .addClass("on")
        .siblings("span")
        .removeClass("on");
      const lang = _index == 1 ? "en-US" : "zh-CN";
      this.$i18n.locale = lang;
      localStorage.setItem("language", lang);
      location.reload();
    },
    to(url) {
      this.$router.push(
        `${url}`,
        () => {},
        () => {}
      );
      this.drawerOpen = false;
    },
    show(index) {
      const that = $(".drawer_ul>li:eq(" + index + ")");
      that
        .find("div.list")
        .toggle()
        .parent("li")
        .siblings("li")
        .find("div.list")
        .hide();
      that.toggleClass("on").siblings("li").removeClass("on");
    },
    show1() {
      this.proShow = !this.proShow;
      this.serShow = false;
    },
    show2() {
      this.serShow = !this.serShow;
      this.proShow = false;
    },
    toggleDrawer() {
      this.drawerOpen = true;
      // document.querySelector("body").style.overflow = "hidden";
    },
    closeDrawer() {
      this.drawerOpen = false;
      // document.querySelector("body").style.overflow = "";
    },
  },
};
</script>

<style scoped>
body,
margin,
padding {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: calc(88rem / 72);
  padding: 0 calc(40rem / 72);
  color: white;
  position: relative;
  z-index: 999;
  background: #383a49;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
  width: 100%;
  box-sizing: border-box;
}
.navbar-active {
  position: fixed;
  top: 0;
  left: 0;
}
.logo img {
  width: calc(212rem / 72);
  height: calc(48rem / 72);
  object-fit: cover;
}

.menu-icon img {
  width: calc(32rem / 72);
  height: calc(32rem / 72);
  cursor: pointer;
}

/* Drawer styles */
.drawer_div {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  transform: translateX(100%);
  z-index: 999;
}
.drawer {
  position: fixed;
  top: 0;
  right: 0;
  width: 75%;
  height: 100%;
  background-color: #fff;
  /* color: #1e2344; */
  transform: translateX(100%);
  transition: transform 0.3s ease;
  z-index: 1000;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

.drawer-open {
  transform: translateX(0);
}

.drawer-content {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.drawer-content .close {
  position: absolute;
  width: calc(48rem / 72);
  height: calc(48rem / 72);
  background: url(@/assets/mobile/close.png) no-repeat;
  background-size: cover;
  top: calc(40rem / 72);
  right: calc(40rem / 72);
}
.drawer-content .mobi_lang {
  padding: calc(128rem / 72) calc(40rem / 72) calc(60rem / 72) calc(40rem / 72);
  display: flex;
}
.drawer-content .mobi_lang span {
  font-weight: 400;
  font-size: calc(24rem / 72);
  color: #000000;
  margin-right: calc(20rem / 72);
}
.drawer-content .mobi_lang span.on {
  color: #b99656;
  border-bottom: #b99656 solid 1px;
}
.drawer-content .mobi_lang img {
  width: calc(30rem / 72);
  height: calc(24rem / 72);
  margin-right: calc(20rem / 72);
}
.drawer-content .router {
  color: #1e2344;
  text-decoration: none;
  padding: calc(30rem / 72) calc(40rem / 72);
  font-size: calc(36rem / 72);
  font-weight: 400;
  letter-spacing: calc(1rem / 72);
  /*display: flex;*/
  /*justify-content: space-between;*/
}
.drawer-content .drawer_ul > li:last-of-type {
  color: #fff;
  background: #b99656;
  position: absolute;
  margin: calc(30rem / 72) calc(40rem / 72);
  padding: calc(20rem / 72);
}
.drawer-content .router.more {
  transform: rotate(180deg);
  background: url(@/assets/mobile/why_list_icon_01.png) no-repeat right
    calc(40rem / 72) top calc(32rem / 72);
  background-size: calc(32rem / 72);
  transform: rotate(0deg);
}
.drawer-content .router.on {
  color: #ba9153;
}
.drawer-content .router.more.on {
  background: url(@/assets/mobile/why_list_icon_02.png) no-repeat right
    calc(40rem / 72) top calc(32rem / 72);
  background-size: calc(32rem / 72);
  transform: rotate(0deg);
}
/*.drawer-content .router.on div {*/
/*  background: url(@/assets/mobile/why_list_icon_02.png);*/
/*  background-size: cover;*/
/*  transform: rotate(0deg);*/
/*}*/

.list {
  border-top: #e4e4e4 1px solid;
  padding-left: calc(50rem / 72);
  margin-top: calc(30rem / 72);
  display: none;
}
.list div {
  font-weight: 400;
  font-size: calc(28rem / 72);
  color: #1e2344;
  margin-top: calc(30rem / 72);
  letter-spacing: calc(1rem / 72);
}
.list > ul > li {
  font-weight: 400;
  font-size: calc(28rem / 72);
  color: #999;
  margin-top: calc(30rem / 72);
  padding-left: calc(10rem / 72);
  letter-spacing: calc(1rem / 72);
}
/*.project ul:last-of-type li:last-of-type,*/
/*.service div:last-of-type {*/
/*  margin-bottom: calc(60rem / 72);*/
/*}*/

.drawer-content .router_bottom {
  border-top: #e4e4e4 1px solid;
  margin-top: calc(160rem / 72);
  padding: calc(40rem / 72);
  display: flex;
}
.drawer-content .router_bottom img {
  width: calc(36rem / 72);
  height: calc(36rem / 72);
  margin-right: calc(40rem / 72);
}
</style>
