<template>
  <footer>
    <img class="mobi_botlogo" src="@/assets/mobile/bottom_logo.png" alt="" />
    <div class="w24pxfff">梦想无限 &ensp;|&ensp; 共创和谐未来</div>
    <div class="mobi_botslogan">
      Boundless dreams &ensp;|&ensp; harmonious future
    </div>
    <div class="mobi_botcen">
      <div class="mobi_botcen_left">
        <div class="icon">
          <img src="@/assets/contact_f.png" alt="" />
          <img src="@/assets/contact_x.png" alt="" />
          <img src="@/assets/contact_z.png" alt="" />
        </div>
        <div class="connection">
          <img src="@/assets/contact_address.png" />
          {{ $t("address") }}
        </div>
        <div class="connection">
          <img src="@/assets/contact_tel.png" />info@futurecitizen.group
        </div>
      </div>
<!--      <div class="mobi_botcen_right">-->
<!--        <div class="icon"><img src="@/assets/mobile/wechat.png" /></div>-->
<!--        <div>立即咨询</div>-->
<!--      </div>-->
    </div>
    <div class="mobi_botnav">
      <template v-for="nav in navList">
        <template v-if="nav.id != 16">
          <div v-if="nav.id == 2 || nav.id == 10" class="w24pxfff">
            {{ nav.name }}
          </div>
          <router-link v-else class="w24pxfff" :to="`${nav.link}`">{{
            nav.name
          }}</router-link>
        </template>
      </template>
    </div>
    <div class="mobi_bottext">{{ $t("bottom") }}</div>
  </footer>
</template>
<script>
import "@/style/mobile/common.css";
import { GetNav } from "@/api/api";
export default {
  name: "FooterView",
  data() {
    return {
      navList: [],
    };
  },
  created() {
    GetNav().then((res) => {
      if (res.data.code == 1) {
        this.navList = res.data.data;
        // localStorage.setItem('navList',JSON.stringify(this.navList))
      }
    });
  },
};
</script>
<style lang="scss" scoped>
footer {
  background: #383a49;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: calc(60rem / 72) calc(40rem / 72);
  .mobi_botlogo {
    width: calc(400rem / 72);
    height: calc(90rem / 72);
    object-fit: cover;
    margin-bottom: calc(30rem / 72);
  }
  .w24pxfff {
    opacity: 0.9;
    margin-bottom: calc(20rem / 72);
  }
  .mobi_botslogan,
  .mobi_bottext {
    color: rgba(255, 255, 255, 0.6);
    font-size: calc(20rem / 72);
    font-weight: 400;
  }
  .mobi_botcen {
    width: 100%;
    margin: calc(50rem / 72) 0 calc(60rem / 72) 0;
    display: flex;
    justify-content: space-between;
    .mobi_botcen_left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .icon {
        display: flex;
        img {
          width: calc(24rem / 72);
          height: calc(24rem / 72);
          object-fit: cover;
          margin-right: calc(20rem / 72);
        }
      }
      .connection {
        display: flex;
        font-weight: 400;
        font-size: calc(24rem / 72);
        color: #ffffff;
        img {
          width: calc(24rem / 72);
          height: calc(24rem / 72);
          object-fit: cover;
          margin-right: calc(20rem / 72);
        }
      }
    }
    .mobi_botcen_right {
      border: 1px solid #ba9153;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: calc(32rem / 72);
      .icon {
        width: calc(32rem / 72);
        height: calc(32rem / 72);
        background: #ba9153;
        border-radius: 50%;
        margin-bottom: calc(10rem / 72);
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: calc(22rem / 72);
          height: calc(22rem / 72);
        }
      }
      div {
        font-weight: 400;
        font-size: calc(20rem / 72);
        color: #ffffff;
        white-space: nowrap;
      }
    }
  }
  .mobi_botnav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: #ba9153 1px solid;
    padding: 0 calc(15rem / 72);
    margin-bottom: calc(20rem / 72);
  }
}
</style>
