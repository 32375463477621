<template>
  <header :class="headerClass">
    <div class="header pr">
      <a href="/" class="logo pa">
        <img src="@/assets/top_logo_new.png" alt="" />
      </a>
      <div class="nav_right pa clearfix">

        <ul class="top_nav fl clearfix">
          <template v-for="(nav, key) in navList">
            <li v-if="nav.childlist.length == 0" class="fl">
              <router-link class="navrouter" :to="`${nav.link}`">{{
                  nav.name
                }}</router-link>
            </li>
            <li
                v-else
                class="fl more pr"
                @click="moreHover(key)"
                @mouseenter="moreHover(key)"
                @mouseleave="moreUnHover(key)"
            >
              <a class="one" href="javascript:;">{{ nav.name }}</a>
              <div class="dl pa" :class="{ dl_en: lang === 'en-US' }">
                <template v-if="nav.childlist[0].childlist.length != 0">
                  <template v-for="nav2 in nav.childlist">
                    <dl>
                      <dt>{{ nav2.name }}</dt>
                      <template v-for="nav3 in nav2.childlist">
                        <dd>
                          <router-link :to="`${nav3.link}`"
                          >&nbsp;•&nbsp;{{ nav3.name }}</router-link
                          >
                        </dd>
                      </template>
                    </dl>
                  </template>
                </template>
                <template v-else>
                  <dl>
                    <template v-for="nav2 in nav.childlist">
                      <dd>
                        <router-link :to="`${nav2.link}`">{{
                            nav2.name
                          }}</router-link>
                      </dd>
                    </template>
                  </dl>
                </template>
              </div>
            </li>
          </template>
        </ul>
        <!--      <div class="lang pa clearfix" @mouseenter="langHover" @click="langClick" @mouseleave="langUnHover">-->
        <div class="lang fl clearfix">
          <span class="fl on" @click="changeLanguage(0)">EN</span>
          <div class="line fl"></div>
          <span class="fl" @click="changeLanguage(1)">CN</span>
          <!--        <ul class="lang_list pa">-->
          <!--          <li @click="switchLang('en-US')">EN</li>-->
          <!--          <li @click="switchLang('zh-CN')">CN</li>-->
          <!--        </ul>-->
        </div>
        <!--      <ul class="lang pa clearfix">-->
        <!--        <li class="fl" @click="changeLanguage('en-US')"><a href="">EN</a></li>-->
        <!--        <li class="fl" @click="changeLanguage('zh-CN')"><a href="">CN</a></li>-->
        <!--      </ul>-->
      </div>
    </div>
  </header>
</template>

<script>
import "@/style/common.css";
import $ from "jquery";
import topDownImage1 from "@/assets/top_up.png";
import topDownImage from "@/assets/top_down.png";
import { GetNav } from "@/api/api";
export default {
  name: "Navbar",
  data() {
    return {
      selectedOption: "",
      lastScrollTop: 0,
      headerClass: "navbar", // 默认类
      navList: [],
      lang: localStorage.getItem("language"),
    };
  },
  created() {
    GetNav().then((res) => {
      if (res.data.code == 1) {
        this.navList = res.data.data;
      }
    });
    const cnsvg = require("@/assets/cn.svg");
    const ensvg = require("@/assets/gb.svg");
    $(function () {
      if (localStorage.getItem("language") == "zh-CN") {
        $(".lang span:eq(1)").addClass("on").siblings("span").removeClass("on");
      } else {
        $(".lang span:eq(0)").addClass("on").siblings("span").removeClass("on");
      }
      // if (localStorage.getItem("language") == "zh-CN") {
      //   $(".lang>span")
      //     .text("CN")
      //     .css({
      //       "background-image": `url(${cnsvg})`,
      //       "background-size": "18px;",
      //     });
      // } else {
      //   $(".lang>span")
      //     .text("EN")
      //     .css({
      //       "background-image": `url(${ensvg})`,
      //       "background-size": "18px;",
      //     });
      // }
    });
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    changeLanguage(_index) {
      $(".lang span:eq('" + _index + "')")
        .addClass("on")
        .siblings("span")
        .removeClass("on");
      const lang = _index == 0 ? "en-US" : "zh-CN";
      this.$i18n.locale = lang;
      localStorage.setItem("language", lang);
      location.reload();
    },
    switchLang(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem("language", lang);
      location.reload();
    },
    langClick() {
      $(".lang_list").fadeToggle();
    },
    langHover() {
      $(".lang_list").fadeIn();
    },
    langUnHover() {
      $(".lang_list").fadeOut();
    },
    moreUnHover(index) {
      const that = $("header .header .top_nav li:eq(" + index + ")");
      that.find(".one").css({
        color: "#FFFFFF",
        "background-image": `url(${topDownImage})`,
        "background-size": "16px 16px;",
      });
      that.find("div.dl").hide();
    },
    moreHover(index) {
      const that = $("header .header .top_nav li:eq(" + index + ")");
      that.find(".one").css({
        color: "#BA9153",
        "background-image": `url(${topDownImage1})`,
        "background-size": "16px 16px;",
      });
      $("header .header .top_nav li.more .dl a").hover(
        function () {
          $(this).parents("dl").find("dt").css({ color: "#BA9153" });
        },
        function () {
          $(this).parents("dl").find("dt").css({ color: "#999999" });
        }
      );
      that.find("div.dl").show();
    },
    handleScroll() {
      const scrollTop = window.scrollY;
      const dlElements = document.querySelectorAll(".dl");
      $(".dl").hide();
      $("header .header .top_nav li").each(function (node, index){

        $(this).find(".one").css({
          color: "#FFFFFF",
          "background-image": `url(${topDownImage})`,
          "background-size": "16px 16px;",
        });
      });
      if (scrollTop > this.lastScrollTop) {
        this.headerClass = "navbar navbar-hidden";

      } else {
        this.headerClass = "navbar";
      }
      if (scrollTop === 0) {
        this.headerClass = "navbar";
      }
      // if (scrollTop === 0) {
      //   this.headerClass = "navbar navbar-transparent";
      //   dlElements.forEach((dl) => dl.classList.add("transparent-dl"));
      // } else {
      //   dlElements.forEach((dl) => dl.classList.remove("transparent-dl"));
      // }

      this.lastScrollTop = scrollTop;
    },
  },
};
</script>
<style lang="scss" scoped>
.navbar {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #383a49;
  transition: background-color 0.3s, transform 0.3s;
}

.navbar-hidden {
  transform: translateY(-100%);
}

.navbar-transparent {
  background-color: rgba(44, 44, 44, 0.8);
}

.lang span {
  cursor: pointer;
}
</style>
