import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// import "lib-flexible/flexible.js";

Vue.config.productionTip = false;

import http from "@/utils/http";
import {GetNav} from "@/api/api";

Vue.prototype.$axios = http;
// Vue.prototype.$axios = http;

import VueI18n from "vue-i18n";
import zh from "@/lang/zh.js";
import en from "@/lang/en.js";
Vue.use(VueI18n);

// const navLang = navigator.language; //判断当前浏览器使用的语言
// const localLang = navLang === "zh-CN" || navLang === "en-US" ? navLang : false;
let lang = localStorage.getItem("language") || "en-US"; //如果有缓存，取缓存的语言
const i18n = new VueI18n({
  locale: lang,
  messages: {
    zh: zh, // 中文语言包
    en: en, // 英文语言包
  },
  silentTranslationWarn: true,
});
localStorage.setItem("language", lang); //最后缓存当前使用的语言

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
