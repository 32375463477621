<template>
  <footer>
    <div class="footer_top">
      <!-- <div class="top_left"></div> -->
      <img class="top_left" src="@/assets/bottom_logo_new.png" />
      <div class="top_right">
        <div class="top_slogan f_hei">梦想无限 | 共创和谐未来</div>
        <div class="top_text f_hei">Boundless Dreams | Harmonious Future</div>
      </div>
    </div>
    <div class="footer_center">
      <div class="cen_left">
        <div class="cen_contact">
          {{ $t("contactus") }}
          <img src="@/assets/contact_f.png" class="contact_img ma20px" />
          <img src="@/assets/contact_x.png" class="contact_img ma20px" />
          <img src="@/assets/contact_z.png" class="contact_img ma20px" />
        </div>
        <div class="cen_text f_hei">
          <img src="@/assets/contact_address.png" class="contact_img ma10px" />
          {{ $t("address") }}
        </div>
        <div class="cen_text f_hei">
          <img src="@/assets/contact_email.png" class="contact_img ma10px" />
          info@futurecitizen.group
        </div>
      </div>
      <div class="cen_right">
        <template v-for="nav in navList">
          <div v-if="nav.id == 2 || nav.id == 10">{{ nav.name }}</div>
          <div v-else>
            <router-link :to="`${nav.link}`">{{ nav.name }}</router-link>
          </div>
        </template>
      </div>
    </div>
    <div class="footer_bottom">{{ $t("bottom") }}</div>
  </footer>
</template>

<script>
import "@/style/common.css";
import { GetNav } from "@/api/api";
export default {
  name: "FooterView",
  data() {
    return {
      navList: [],
    };
  },
  created() {
    GetNav().then((res) => {
      if (res.data.code == 1) {
        this.navList = res.data.data;
        // localStorage.setItem('navList',JSON.stringify(this.navList))
      }
    });
  },
};
</script>
<style lang="scss"></style>
