export default {
  // 首页
  aboutus: "ABOUT US",
  contactus:"CONTACT US",
  downpdf:"DOWNLOAD",
  getData:"ACQUIRING DATA",
  serviceOverview: "SERVICE OVERVIEW",
  coreDomain: "CORE DOMAIN",
  projectPlan: "PROJECT PLAN",
  news: "NEWS",
  LearnMore:"LEARN MORE",
  banner_text1:"CITIZEN SERVICES",
  banner_text2:"CORPORATE SERVICES",
  banner_text3:"GOVERNMENT SERVICES",
  banner_desc11:"Our citizen residency and relocation programs are designed to provide comprehensive support and services for those who wish to live and work in a foreign country.",
  banner_desc12:"We are committed to making your relocation process simple and efficient, regardless of your chosen destination.",
  banner_desc13:"We place particular emphasis on selecting and designing specific residency programs to achieve and safeguard the interests and goals of all stakeholders.",
  banner_desc21:"Future Citizen Bureau corporate solutions are dedicated to providing customized global mobility products and services that help businesses succeed in the global market.",
  banner_desc22:"We focus on designing tailored products based on the specific needs of each business, enabling them to better serve their clients and create greater economic and social value.",
  banner_desc31:"Future Citizen Bureau government solutions focus on providing innovative and sustainable solutions to governments worldwide, helping them address the challenges and opportunities brought by globalization.",
  banner_desc32:"We emphasize tailoring solutions to the specific needs of governments to enhance economic momentum and social productivity, ensuring long-term stable development.",
  bahamas:"BAHAMAS",
  slovenia:"SLOVENIA",
  ireland:"IRELAND",
  vanuatu:"VANUATU",
  Citizenship:"CITIZENSHIP PROGRAMS",
  Residency:"RESIDENCY PROGRAMS",
  countryAdv:"NATIONAL ADVANTAGES",
  projectAdv:"PROJECT ADVANTAGES",
  process:"APPLICATION PROCESS",
  projectStep:"PROJECT IMPLEMENTATION STEPS",
  investor:"INVESTORS' CONTRIBUTIONS AND OPPORTUNITIES",
  whychoiceus:"WHY CHOOSE US",
  usteam:"OUR TEAM",
  usservice:"OUR SERVICES",
  mission:"Mission",
  vision:"Vision",
  address:"Room 1012, Tower T1 Building C, Wangjing SOHO, Chaoyang District, Beijing,  China",
  helloDesc:"Thank You for Visiting Future Citizen Bureau, We encourage all individuals passionate about globalization and sustainable development to connect with us and join our ranks. Together, we can contribute to the progress and prosperity of the global society. We look forward to partnering with you to explore the endless possibilities of the future.",
  messageBord:"MESSAGE BORD",
  username:"Name",
  phone:"Phone",
  email:"Email",
  message:"Your Message",
  submit:"SUBMIT",
  pleaseLeave:"Please leave your contact information",
  pleaseCorrect:"Please provide the correct email address for us to contact you",
  submitSuccess:"SUBMIT SUCCESSFULLY",
  bottom:"2024 FUTURE CITIZEN BUREAU. All Rights Reserved.",
  newsPrev:"PREVIOUS POST",
  newsNext:"NEXT CHAPTER",
  newsReturnList:"RETURN LIST",
};
