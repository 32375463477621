<template>
  <div>
    <Navbar v-if="!isMobile"></Navbar>
    <MobiNavbar v-else></MobiNavbar>
    <router-view></router-view>
    <Footer v-if="!isMobile"></Footer>
    <MobiFooter v-else></MobiFooter>
    <!-- <Navbar></Navbar>
    <router-view></router-view>
    <Footer></Footer> -->
  </div>
</template>

<script>
import Footer from "@/layout/components/Footer.vue";
import Navbar from "@/layout/components/Navbar.vue";
import MobiNavbar from "@/layout/components/mobile/Navbar.vue";
import MobiFooter from "@/layout/components/mobile/Footer.vue";
import "@/style/reset.css";
export default {
  name: "Layout",
  data() {
    return {
      isMobile: /Mobi|Android/i.test(navigator.userAgent),
    };
  },
  components: {
    Footer,
    Navbar,
    MobiNavbar,
    MobiFooter,
  },

  mounted() {
    console.log(this.isMobile, "isMobile");
    // this.isMobile = /Mobi|Android/i.test(navigator.userAgent);
  },
};
</script>
<style lang="scss"></style>
